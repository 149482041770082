import React from 'react';

function Question({ question, answer, first }) {
  const divClass = first ? '' : 'mt-12';
  return (
    <div className={divClass}>
      <dt className="text-lg leading-6 font-medium text-gray-900">
        {question}
      </dt>
      <dd className="mt-2">
        <p className="text-base leading-6 text-gray-500">
          {answer}
        </p>
      </dd>
    </div>
  );
}

export default Question;
