import React from 'react';

function SplitWithImage({ title, superTitle, bodyHtml, imageSrc, leadingText }) {
  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
          <img className="h-56 w-full object-cover lg:absolute lg:h-full" src={imageSrc} alt={title} />
        </div>
      </div>
      <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="lg:col-start-2 lg:pl-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
            {superTitle && <p className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">{superTitle}</p>}
            <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">{title}</h1>
            {leadingText && <p className="text-lg leading-7 text-gray-500 mb-5">{leadingText}</p>}
            <div className="prose text-gray-500" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SplitWithImage;
