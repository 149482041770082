import React from 'react';
import { Link } from 'gatsby';

function SimpleCentered({ title, titleHighlight, buttonHref, buttonText }) {

  return (
    <div className="bg-indigo-100">
      <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          {title}
          <br />
          <span className="text-indigo-600">{titleHighlight}</span>
        </h2>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <Link to={buttonHref} className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
              {buttonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimpleCentered;
