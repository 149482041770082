import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/sections/section';


class PageTemplate extends React.Component {
  render() {
    const item = this.props.data.contentfulPage;
    const { permalink } = this.props.pageContext;
    const { title, sections } = item;

    return (
      <Layout>
        <SEO title={title} type="article" permalink={`${process.env.GATSBY_APP_URL}${permalink}`} />
        {sections.map(section => <Section key={section.id} section={section} type={section.__typename} />)}
      </Layout>
    );
  }
}
export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($permalink: String!) {
    contentfulPage(permalink: { eq: $permalink }) {
      permalink
        id
        title
        sections {
          ... on ContentfulSectionContentSingleCentered {
            id
            body {
              childMarkdownRemark {
                html
              }
            }
            image {
              file {
                url
              }
            }
            superTitle
            leadingText {
              leadingText
            }
            title
          }
          ... on ContentfulSectionContentSplitWithImage {
            id
            body {
              childMarkdownRemark {
                html
              }
            }
            image {
              file {
                url
              }
            }
            superTitle
            title
            leadingText {
              leadingText
            }
          }
          ... on ContentfulSectionContentTwoColumns {
            id
            button {
              buttonText
              buttonHref
            }
            leftColBody {
              childMarkdownRemark {
                html
              }
            }
            rightColBody {
              childMarkdownRemark {
                html
              }
            }
            superTitle
            title
          }
          ... on ContentfulSectionContentTwoColumnsWithTestimonial {
            id
            leadingText {
              leadingText
            }
            body {
              childMarkdownRemark {
                html
              }
            }
            superTitle
            title
            ctaButton {
              buttonHref
              buttonText
            }
            testimonial {
              body {
                body
              }
              title
              name
              image {
                file {
                  url
                }
              }
              id
            }
          }
          ... on ContentfulSectionCtaSimple {
            id
            ctaButton {
              buttonHref
              buttonText
            }
            displayType
            title
            titleHighlight
          }
          ... on ContentfulSectionFaqTwoColumns {
            id
            title
            leftColumnQuestionsAnswers {
              answer {
                answer
              }
              question
              id
            }
            rightColumnQuestionsAnswers {
              id
              answer {
                answer
              }
              question
            }
          }
          ... on ContentfulSectionFeatureSimpleThreeColumn {
            id
            centerColumnContent {
              centerColumnContent
            }
            centerColumnTitle
            leftColumnTitle
            leftColumnContent {
              leftColumnContent
            }
            rightColumnTitle
            rightColumnContent {
              rightColumnContent
            }
          }
          ... on ContentfulSectionHeroSimpleCentered {
            id
            body {
              body
            }
            ctaButton {
              buttonHref
              buttonText
            }
            title
            titleHighlight
          }
          ... on ContentfulSectionHeroSplitWithImage {
            id
            body {
              body
            }
            ctaButton {
              buttonHref
              buttonText
            }
            displayType
            image {
              file {
                url
              }
            }
            title
            titleHighlight
          }
          ... on ContentfulSectionTestimonialsSimpleCentered {
            id
            testimonial {
              body {
                body
              }
              image {
                file {
                  url
                }
              }
              name
              title
            }
          }
          ... on ContentfulSectionTestimonialsThreeColumn {
            id
            testimonials {
              body {
                body
              }
              id
              image {
                file {
                  url
                }
              }
              name
              title
            }
          }
        }
    }
  }
`;