import React from 'react';
import { Link } from 'gatsby';

function TwoColumnsWithTestimonial({ title, superTitle, bodyHtml, testimonialAvatarImageSrc, testimonial, testimonialName, testimonialTitle, leadingText, buttonHref, buttonText }) {
  const hasButton = (buttonText && buttonHref);

  return (
    <div className="py-16 bg-gray-50 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-base max-w-prose mx-auto lg:max-w-none">
          {superTitle && <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">{superTitle}</p>}
          <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">{title}</h1>
        </div>
        <div className="relative z-10 text-base max-w-prose mx-auto mb-8 lg:max-w-5xl lg:mx-0 lg:pr-72">
          <p className="text-lg text-gray-500 leading-7">{leadingText}</p>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
          <div className="relative z-10 mb-12 lg:mb-0">
            <div className="mb-10 prose text-gray-500 mx-auto lg:max-w-none" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
            {hasButton && (
              <div className="flex text-base max-w-prose mx-auto lg:max-w-none">
                <div className="rounded-md shadow">
                  <Link to={buttonHref} className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out">
                    {buttonText}
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="relative text-base max-w-prose mx-auto lg:max-w-none">
            <svg className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="bedc54bc-7371-44a2-a2bc-dc68d819ae60" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)" />
            </svg>
            <blockquote className="relative bg-white rounded-lg shadow-lg">
              <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
                <div className="relative text-lg text-gray-700 leading-7 font-medium mt-8">
                  <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-200" fill="currentColor" viewBox="0 0 32 32">
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="relative">{testimonial}</p>
                </div>
              </div>
              <cite className="flex items-center sm:items-start bg-indigo-600 rounded-b-lg not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
                <div className="rounded-full border-2 border-white mr-4 sm:-mt-15 sm:mr-6">
                  {testimonialAvatarImageSrc && <img src={testimonialAvatarImageSrc} className="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-indigo-300" alt={testimonialName} />}
                </div>
                <span className="text-indigo-300 font-semibold leading-6">
                  <strong className="text-white font-semibold">{testimonialName}</strong>
                  {testimonialTitle && (
                    <>
                      <br className="sm:hidden" />
                      <span> {testimonialTitle}</span>
                    </>
                  )}
                </span>
              </cite>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwoColumnsWithTestimonial;
