import React from 'react';
import HeroSimpleCentered from './hero/simple-centered';
import HeroSplitWithImage from './hero/split-with-image';
import HeroSplitWithAngledImage from './hero/split-with-angled-image';
import TestimonialSimpleCentered from './testimonial/simple-centered';
import TestimonialThreeColumn from './testimonial/three-columns';
import FeatureSimpleThreeColumn from './feature/simple-three-columns';
import FaqTwoColumn from './faq/two-columns';
import CtaSimpleCentered from './cta/simple-centered';
import CtaSimpleJustified from './cta/simple-justified';
import ContentSingleCentered from './content/single-centered';
import ContentSplitWithImage from './content/split-with-image';
import ContentTwoColumn from './content/two-columns';
import ContentTwoColumnWithTestimonial from './content/two-columns-with-testimonial';

// TODO: Move these to a util
function grabUrl(image) {
  if (image && image.file) {
    return image.file.url;
  }
  return null;
}

function grabButton(btn) {
  const ret = {};
  if (btn) {
    ret.buttonHref = btn.buttonHref;
    ret.buttonText = btn.buttonText;
  }
  return ret;
}

// Type scoping (so we don't get "title already declared" errors)
function HeroSplit({ section }) {
  const { title, titleHighlight, body: { body }, ctaButton, image, displayType } = section;
  const url = grabUrl(image);
  const { buttonHref, buttonText } = grabButton(ctaButton);
  if (displayType === 'Angled') {
    return <HeroSplitWithAngledImage title={title} titleHighlight={titleHighlight} body={body} buttonHref={buttonHref} buttonText={buttonText} imageSrc={url} />;
  }
  return <HeroSplitWithImage title={title} titleHighlight={titleHighlight} body={body} buttonHref={buttonHref} buttonText={buttonText} imageSrc={url} />;
}
function HeroSimple({ section }) {
  const { title, titleHighlight, body: { body }, ctaButton } = section;
  const { buttonHref, buttonText } = grabButton(ctaButton);
  return <HeroSimpleCentered title={title} titleHighlight={titleHighlight} body={body} buttonHref={buttonHref} buttonText={buttonText} />;
}
function TestimonialsSimple({ section }) {
  const { testimonial: { name, title, body: { body }, image } } = section;
  const url = grabUrl(image);
  return <TestimonialSimpleCentered title={title} name={name} body={body} imageSrc={url} />;
}
function Testimonial3Col({ section }) {
  // console.log(section, '3 col...');
  // return null;
  const { testimonials } = section;
  const formattedTestimonials = testimonials.map(item => {
    const { name, title, body: { body }, image, id } = item;
    const imageSrc = grabUrl(image);
    return { name, title, body, imageSrc, id };
  });
  return <TestimonialThreeColumn testimonials={formattedTestimonials} />;
}
function Feature3Col({ section }) {
  const { leftColumnTitle, leftColumnContent: { leftColumnContent }, centerColumnTitle, centerColumnContent: { centerColumnContent }, rightColumnTitle, rightColumnContent: { rightColumnContent } } = section;
  return <FeatureSimpleThreeColumn leftColTitle={leftColumnTitle} leftColContent={leftColumnContent} centerColTitle={centerColumnTitle} centerColContent={centerColumnContent} rightColTitle={rightColumnTitle} rightColContent={rightColumnContent} />;
}
function Faq2Col({ section }) {
  const { title, leftColumnQuestionsAnswers, rightColumnQuestionsAnswers } = section;
  // Prep the arrays
  const leftColQuestions = leftColumnQuestionsAnswers.map(item => {
    const { id, question, answer: { answer } } = item;
    return { id, question, answer };
  });
  const rightColQuestions = rightColumnQuestionsAnswers.map(item => {
    const { id, question, answer: { answer } } = item;
    return { id, question, answer };
  });
  return <FaqTwoColumn title={title} leftColQuestions={leftColQuestions} rightColQuestions={rightColQuestions} />;
}
function CtaSimple({ section }) {
  const { displayType, title, titleHighlight, ctaButton } = section;
  const { buttonHref, buttonText } = grabButton(ctaButton);
  if (displayType === 'Justified') {
    return <CtaSimpleJustified title={title} titleHighlight={titleHighlight} buttonHref={buttonHref} buttonText={buttonText} />;
  }
  return <CtaSimpleCentered title={title} titleHighlight={titleHighlight} buttonHref={buttonHref} buttonText={buttonText} />;
}
function ContentSingle({ section }) {
  const { title, superTitle, leadingText: { leadingText }, image, body: { childMarkdownRemark: { html } } } = section;
  const url = grabUrl(image);
  return <ContentSingleCentered title={title} superTitle={superTitle} leadingText={leadingText} bodyHtml={html} imageSrc={url} />;
}
function Content2ColTestimonial({ section }) {
  const { title, superTitle, leadingText: { leadingText }, ctaButton, body: { childMarkdownRemark: { html } }, testimonial } = section;
  const { buttonHref, buttonText } = grabButton(ctaButton);
  const testimonialBody = testimonial.body.body;
  const testimonialImage = testimonial.image ? testimonial.image.file.url : null;
  const testimonialName = testimonial.name;
  const testimonialTitle = testimonial.title;
  return <ContentTwoColumnWithTestimonial title={title} superTitle={superTitle} leadingText={leadingText} bodyHtml={html} buttonHref={buttonHref} buttonText={buttonText} testimonial={testimonialBody} testimonialAvatarImageSrc={testimonialImage} testimonialName={testimonialName} testimonialTitle={testimonialTitle} />;
}
function Content2Col({ section }) {
  const { title, superTitle, button, leftColBody, rightColBody } = section;
  const { buttonHref, buttonText } = grabButton(button);
  const leftHtml = leftColBody.childMarkdownRemark.html;
  const rightHtml = rightColBody.childMarkdownRemark.html;
  return <ContentTwoColumn title={title} superTitle={superTitle} leftColHtml={leftHtml} rightColHtml={rightHtml} buttonText={buttonText} buttonHref={buttonHref} />;
}
function ContentSplitImage({ section }) {
  const { title, superTitle, leadingText: { leadingText }, image, body: { childMarkdownRemark: { html } } } = section;
  const url = grabUrl(image);
  return <ContentSplitWithImage title={title} superTitle={superTitle} leadingText={leadingText} bodyHtml={html} imageSrc={url} />;
}


function Section({ type, section }) {
  // Determine the section return value based on type

  switch (type) {

    // Hero sections
    case 'ContentfulSectionHeroSplitWithImage':
      return <HeroSplit section={section} />;
    case 'ContentfulSectionHeroSimpleCentered':
      return <HeroSimple section={section} />;

    // Testimonials sections
    case 'ContentfulSectionTestimonialsSimpleCentered':
      return <TestimonialsSimple section={section} />;
    case 'ContentfulSectionTestimonialsThreeColumn':
      return <Testimonial3Col section={section} />;

    // Feature sections
    case 'ContentfulSectionFeatureSimpleThreeColumn':
      return <Feature3Col section={section} />;

    // FAQ sections
    case 'ContentfulSectionFaqTwoColumns':
      return <Faq2Col section={section} />;

    // CTA sections
    case 'ContentfulSectionCtaSimple':
      return <CtaSimple section={section} />;

    // Content sections
    case 'ContentfulSectionContentSingleCentered':
      return <ContentSingle section={section} />;
    case 'ContentfulSectionContentTwoColumnsWithTestimonial':
      return <Content2ColTestimonial section={section} />;
    case 'ContentfulSectionContentTwoColumns':
      return <Content2Col section={section} />;
    case 'ContentfulSectionContentSplitWithImage':
      return <ContentSplitImage section={section} />;

    default:
      return null;
  }
}

export default Section;
