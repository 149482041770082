import React from 'react';
import Question from './question';

function TwoColumns({ title, leftColQuestions, rightColQuestions }) {

  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
        <h2 className="text-3xl leading-9 font-extrabold text-gray-900">
          {title}
        </h2>
        <div className="mt-6 border-t-2 border-gray-100 pt-10">
          <dl className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              {leftColQuestions.map((question, i) => <Question key={question.id} first={(i === 0)} {...question} />)}
            </div>
            <div className="mt-12 md:mt-0">
              {rightColQuestions.map((question, i) => <Question key={question.id} first={(i === 0)} {...question} />)}
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default TwoColumns;
